/* fonts----------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.home_content {
    height: 100%;
    transform: translateZ(0);
    background-color: black;
  
  }
  
  .home_block {
    position: relative;
    height: 100vh;
    background-color: black;
  }
  
  [class*=item-parallax] {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-out;
    will-change: transform;
  }
  
  .home_item-parallax-media {
    z-index: 1;
    background-color: black;
  }
  
  /* ---------------inicio background bolitas------------------------ */

  
  .home_area_circulos {
    overflow: hidden; /* Asegúrate de que los elementos que se salen del área visible no creen scroll horizontal */
    position: relative; /* Si aún no está establecido */
    width: 100%; /* Ajusta al ancho del área de visualización */
    height: 90%; /* Ajusta al alto deseado */
}

  .home_circulos{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .home_circulos li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #7F6ADC;
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .home_circulos li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    background-color: #C3F53E;
  
  }
  .home_circulos li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .home_circulos li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
    background-color: #C3F53E;
  
  }
  .home_circulos li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  
  .home_circulos li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  
  .home_circulos li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  
  .home_circulos li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  
  .home_circulos li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
    background-color: #C3F53E;;
  }
  
  .home_circulos li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    background-color: #C3F53E;
  }
  @keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 100%;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 100%;
    }
  
  }
  
  /* ---------------------------fin de background de bolitas-------------------------------- */
  .home_item-parallax-content {
    z-index: 2;
  }
  .home_block:nth-child(1) .home_item-parallax-content {
    transform: translateY(calc( var(--yBlock-1) * 0.5px ));
  }
  .home_block:nth-child(2) .home_item-parallax-content {
    transform: translateY(calc( var(--yBlock-2) * 0.4px ));
  }
  .home_block:nth-child(3) .home_item-parallax-content {
    transform: translateY(calc( var(--yBlock-3) * 0.4px ));
  }
  .home_block:nth-child(4) .home_item-parallax-content {
    transform: translateY(calc( var(--yBlock-4) * 0.4px ));
  }
  
  .home_flex-container {
  margin: auto;
  }
  
  .home_centered-content {
    margin-top: 10%;
    flex: auto;
  }

  .home_centered-content, .content-wrapper {
    text-align: center;
}
  
    
  .home_h1{
  text-align: center;
  font-size: 3.5rem;
  color: #ffffff;
  font-family: 'Manrope';
  font-weight: 800;
  margin-top: 2rem;
  }

  .home_h2{
    text-align: center;
    font-size: 2rem;
    color: #C3F53E;
    font-family: 'Manrope';
    margin-top: 2rem;
    margin-bottom: 1rem;
    }

    .home-app-link img {
      max-width: 135px; /* Ajusta el tamaño de los logos de las apps */
      margin: 1rem;
  }
      
  .home_p_principal{
  font-family: 'Manrope';
  color: #ffffff;
  text-align: center;
  font-size: 2rem;
  }
  
  .contenedor_home_btn{
    text-align: center;
  }
  
  .home_btn{
  width: 20%;
  height: 65px;
  background: #7F6ADC;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Manrope';
  color: #fff;
  margin-top: 50px;
  cursor: pointer;
  }
      
  .home_a{
  font-family: "Manrope";
  }
  
  
  @-webkit-keyframes bounce {
    0%, 100% {
        transform: translate(0, 0) rotateZ(45deg);
    }
    50% {
        transform: translate(0, -15px) rotateZ(45deg);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
        transform: translate(0, 0) rotateZ(45deg);
    }
    50% {
        transform: translate(0, -15px) rotateZ(45deg);
    }
  }
  
  /* ---fin portada--------------------------------------------------------------------- */


  /* -------------categorias-----------------------------------------------------------------------*/
  
  .home_texto_categorias{
  text-align: center;
  color: #7F6ADC;
  font-size: 40px;
  font-family: "Manrope";
  font-weight: bold;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
  }
    
  .home_texto_p_categorias{
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-family: "Manrope";
  font-weight: normal;
  width: 35%;
  display: flex;
  margin: auto;
  padding-top: 45px;
  }
  
  .home_hovervideo_wrapper{
    width: 90%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
   }
  
  .home_hovervideo_videobox{
   width: 30%;
   height: 400px;
   overflow: hidden;
  }
       
  .home_hovervideo_videobox .home_video{
   height: 100%;
   width: 100%;
   object-fit: cover;
   filter: brightness(65%) contrast(124%) saturate(108%) blur(0px) hue-rotate(0deg);
  }
  
  .home_hovervideo_videobox .home_video::-webkit-media-controls{
   display: none !important;
  }
  
  .home_video_card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -120px;
    height: 40px;
    position: relative;
    color:#fff
  }
  /* -----------fin--categorias------------------------------------------------------------------*/
  /* -------------Adultos------------------------------------------------------------------*/

  .adultos-section {
    background-color: #000;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    height: 550px;
    padding-left: 230px;
    padding-right: 350px;
    padding-bottom: 80px;
  }
  
  .adultos-section-text {
    flex: 1;
    text-align: left;
    margin-right: 30px;
  }
  
  .home_p_adultos {
    font-family: 'Manrope';
    color: #ffffff;
    font-size: 1.5rem;
    padding-right: 20%;
  }
  
  .home_adultos {
    font-size: 3.5rem;
    color: #ffffff;
    font-family: 'Manrope';
    font-weight: 800;
  }
  
  .special-text {
    font-size: 3.5rem;
    font-weight: bold;
    color: #7F6ADC;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Manrope';
    font-weight: 800;
  }
  
  .adultos-section-img {
    flex-shrink: 0;
  }
  
  .adultos-section-img img {
    max-width: 85%;
    height: auto;
  }

  /* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .adultos-section {
    flex-direction: column; /* Colapsar la disposición en una sola columna */
    justify-content: center;
    align-items: center;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    padding-bottom: 50px;
  }

  .adultos-section-text {
    margin-right: 0;
    padding: 0;
    align-items: center;
    text-align: center;
  }

  .home_p_adultos {
    font-size: 1.5rem;
    padding-right: 0;
    padding-left: 0;
  }

  .home_adultos {
    font-size: 2rem; /* Reducir el tamaño del título */
  }

  .special-text {
    font-size: 2rem; /* Reducir el tamaño del texto especial */
  }

  .adultos-section-img img {
    max-width: 50%; /* Asegurarse de que la imagen ocupe el ancho completo del contenedor */
    height: auto;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .home_adultos {
    font-size: 2rem; /* Reducir aún más el tamaño del título en pantallas muy pequeñas */
  }

  .special-text {
    font-size: 2rem;
  }

  .home_p_adultos {
    font-size: 1rem;
  }
}

  /* -------------Fin adultos------------------------------------------------------------------*/


  /* -------------Inicio App------------------------------------------------------------------*/

.app-section {
  background-color: #000; 
  padding: 50px 100px; 
  height: 1000px;
}

.app_centered-content {
  text-align: center;
  margin-bottom: 50px;
  height: 200px;
}

.app_content-columns {
  display: flex;
  justify-content: space-between; /* Distribuye la imagen y el contenido */
  align-items: flex-start;
}

.app-section-img {
  flex: 1;
  margin-left: 150px;
}

.app-section-img img {
  max-width: 50%;
  height: auto;
}

.app_right-content {
  flex: 1;
  margin-top: 50px;
}

.app_dots_content {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}

.app_dots_content-img {
  margin-right: 20px;
}

.app_dots_content-img img {
  width: 70px;
  height: auto;
}

.home_app {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  text-align: left;
  font-family: "Manrope";
  font-weight: 800;
}

.home_p_app {
  margin: 0;
  color: #bbb;
  font-size: 1.2rem;
  text-align: left;
  padding-right: 150px;
  font-family: "Manrope";
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .app-section {
    padding: 20px; /* Reducir padding en móviles */
    height: auto; /* Ajustar altura automáticamente en móviles */
  }

  .app_content-columns {
    flex-direction: column; /* Cambiar a disposición en columna */
    align-items: center; /* Alinear al centro */
  }

  .app-section-img {
    margin-left: 0; /* Quitar margen izquierdo */
    margin-bottom: 20px; /* Añadir margen inferior para espaciar */
  }

  .app-section-img img {
    max-width: 80%; /* Hacer la imagen más pequeña en móviles */
  }

  .app_dots_content {
    flex-direction: column; /* Colocar el GIF y el texto en vertical */
    align-items: center; /* Centrar los elementos */
    text-align: center; /* Alinear el texto al centro */
  }

  .app_dots_content-img {
    margin-right: 0; /* Quitar margen derecho */
    margin-bottom: 10px; /* Añadir margen inferior */
  }

  .home_app {
    font-size: 1.5rem; /* Reducir el tamaño del título */
    text-align: center; /* Centrar el texto */
    font-family: "Manrope";
    font-weight: 800;

  }

  .home_p_app {
    font-size: 1rem; /* Reducir el tamaño del párrafo */
    padding-right: 0; /* Quitar padding derecho */
    text-align: center; /* Centrar el texto */
    font-family: "Manrope";
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 480px) {
  .home_app {
    font-size: 1.5rem; /* Reducir aún más el tamaño del título */
  }

  .home_p_app {
    font-size: 1rem; /* Reducir el tamaño del texto en pantallas muy pequeñas */
  }

  .app_dots_content-img img {
    width: 50px; /* Hacer los GIFs más pequeños en móviles */
  }
}
  /* -------------Fin adultos------------------------------------------------------------------*/

    /* -------------Inicio Servicios------------------------------------------------------------------*/
    .servicios-section {
      text-align: center;
      padding: 40px 0;
      padding-bottom: 80px;
    }
    
    .servicios_centered-content {
      margin-bottom: 80px;
    }
    
    .servicios-grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      width: 80%;
      max-width: 1200px;
      margin: 0 auto;
    }
    
    .servicios-grid-item {
      position: relative;
      background-color: #1a1a1a;
      text-align: center;
      border-radius: 10px;
      color: white;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      border: 2px solid transparent;
      overflow: hidden;
      height: 250px;
    }
    
    .circle {
      position: absolute;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      transition: clip-path 0.6s ease;
      z-index: 0;
      opacity: 0.6;
      /* No establecer clip-path aquí, se establece en los selectores nth-child */
      will-change: clip-path;
    }
    
    /* Asignación de imágenes de fondo y clip-path específicos */
    
    .servicios-grid-item:nth-child(1) .circle {
      background-image: url("https://media.giphy.com/media/xTiN0El0cgba5ck65O/giphy.gif");
      clip-path: circle(200px at 100% 100%);
    }
    
    .servicios-grid-item:nth-child(2) .circle {
      background-image: url("https://media.giphy.com/media/oX8pSaFrQw3sJ0K5bk/giphy.gif");
      clip-path: circle(200px at 0% 100%);
    }
    
    .servicios-grid-item:nth-child(3) .circle {
      background-image: url("https://media.giphy.com/media/26u43GwxpIiUgrHI4/giphy.gif");
      clip-path: circle(200px at 100% 0%);
    }
    
    .servicios-grid-item:nth-child(4) .circle {
      background-image: url("https://media.giphy.com/media/m0MfjLtKOgTPG/giphy.gif");
      clip-path: circle(200px at 0% 0%);
    }
    
    /* Animación al hacer hover en desktop */
    
    .servicios-grid-item:hover .circle {
      clip-path: circle(150% at 50% 50%); /* Ajusta este valor si es necesario */
    }
    
    .servicios-grid-item:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.8);
    }
    
    /* Estilo para el botón "Ver más info" */
    
    .btn-ver-mas {
      opacity: 0;
      text-align: center;
      padding: 10px 10px;
      background-color: #C3F53E;
      color: #7F6ADC;
      text-decoration: none;
      border-radius: 5px;
      font-size: 1rem;
      margin-top: 10px;
      transition: opacity 0.6s ease, background-color 0.6s ease;
      font-family: "Manrope";
      position: relative;
    }
    
    .servicios-grid-item:hover .btn-ver-mas {
      opacity: 1;
      z-index: 10;
      display: inline-block;
    }
    
    /* Otros estilos de texto y contenido */
    
    .home_h2_servicios {
      text-align: right;
      font-size: 2rem;
      color: #ffffff;
      font-family: 'Manrope';
      font-weight: 800;
      margin-top: 2rem;
    }
    
    .content {
      text-align: right;
      padding: 25px 35px;
      position: absolute;
    }
    
    .content-left {
      text-align: left;
      padding: 25px 35px;
      position: absolute;
    }
    
    .home_h2_servicios-left {
      text-align: left;
      font-size: 2rem;
      color: #ffffff;
      font-family: 'Manrope';
      font-weight: 800;
      margin-top: 2rem;
    }
    
    .home_p_servicios-left {
      font-family: 'Manrope';
      color: #ffffff;
      font-size: 1rem;
      padding-right: 35%;
    }

    .home_p_servicios {
      font-family: 'Manrope';
      color: #ffffff;
      font-size: 1rem;
      padding-left: 35%;
    }
    
    /* Ajustes para pantallas móviles */
    
    @media (max-width: 768px) {
      .servicios-grid-container {
        grid-template-columns: 1fr;
        gap: 40px;
        width: 100%;
        padding: 0 20px;
      }
    
      .servicios-grid-item {
        position: relative;
        height: auto;
      }
    
      .circle {
        clip-path: none; /* Eliminar cualquier clip-path en móviles */
        width: 100%;
        height: auto;
        opacity: 1;
        position: relative; /* Ajustar posición */
      }
    
      /* Asegurar que al hacer hover no se aplique clip-path en móviles */
      .servicios-grid-item:hover .circle {
        clip-path: none;
      }
    
      /* Mostrar siempre el botón en móviles */
      .btn-ver-mas {
        opacity: 1;
      }
    
      .content,
      .content-left {
        position: static;
        text-align: center;
        padding: 20px;
      }
    
      .home_h2_servicios,
      .home_h2_servicios-left {
        font-size: 1.5rem;
        text-align: center;
      }
    
      .home_p_servicios,
      .home_p_servicios-left {
        font-size: 1rem;
        padding: 0;
        text-align: center;
      }
    
      .btn-ver-mas {
        margin: 20px auto;
        display: block;
        padding: 12px 20px;
        width: 80%;
      }
    }
    
    @media (max-width: 480px) {
      .home_h2_servicios,
      .home_h2_servicios-left {
        font-size: 1.2rem;
      }
    
      .home_p_servicios,
      .home_p_servicios-left {
        font-size: 0.9rem;
      }
    
      .circle {
        height: auto;
      }
    }



  /* -------------Fin Servicios------------------------------------------------------------------*/
  
  /* -----------Inicio Friends------------------------------------------------------------------*/

  .friends-section {
    background-color: #000;
    position: relative;
    overflow: hidden;
    padding: 60px 20px; /* Añadimos padding en lugar de altura fija */
    height: 100%;
  }
  
  .friends-section_texto {
    color: #fff;
    margin: 0 auto 20px;
    font-size: 3.5rem;
    font-family: 'Manrope';
    font-weight: 800;
    text-align: center;
    width: 50%;
  }
  
  
  .friends-section-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    flex-wrap: wrap;
  }
  
  .friends-section-img img {
    max-width: 40%;
    height: auto;
    max-height: 80px;
    margin: 10px 20px;
  }
  
  .highlight {
    color: #7F6ADC; 
    font-size: 55px;
  }
  
  /* Ajustes para tablets y móviles */
  @media (max-width: 768px) {
    .friends-section {
      padding: 40px 15px;
      height: auto;
    }
  
    .friends-section_texto {
      font-size: 2rem;
      width: 80%;
    }
  
    .home_p_principal {
      font-size: 1.5rem;
      margin-bottom: 30px;
    }
  
    .friends-section-img img {
      max-width: 45%;
      max-height: 70px;
      margin: 10px 10px;
    }
  }
  
  /* Ajustes para pantallas muy pequeñas */
  @media (max-width: 480px) {
    .friends-section {
      padding: 30px 10px;
      height: auto;
    }
  
    .friends-section_texto {
      font-size: 2rem;
      width: 90%;
    }
  
    .home_p_principal {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  
    .friends-section-img img {
      max-width: 80%;
      max-height: 60px;
      margin: 10px 0;
    }
  }
  
  /* -----------Fin Friends------------------------------------------------------------------*/
/* -----------Inicio--final------------------------------------------------------------------*/


.final-section {
  background-color: #000;
  position: relative;
  overflow: hidden;
  padding: 60px 0; /* Añadir padding vertical */
  margin-top: 80px;
}

.final_texto {
  color: #fff;
  font-size: 4rem;
  font-family: 'Manrope';
  font-weight: 800;
  width: 45%;
  margin: 0 auto 20px;
  text-align: center;
}

.contenedor_home_btn {
  text-align: center;
  margin-top: 40px;
}

.home_h2 {
  color: #C3F53E;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 20px;
}

.home-app-link {
  display: inline-block;
  margin: 0 10px;
}

.home-app-link img {
  width: 150px;
  height: auto;
}

/* Ajustes para tablets y móviles */
@media (max-width: 768px) {
  .final_texto {
    font-size: 3rem;
    width: 70%;
  }

  .home_h2 {
    font-size: 1.5rem;
  }

  .home-app-link img {
    width: 130px;
  }
}

/* Ajustes para pantallas muy pequeñas */
@media (max-width: 480px) {
  .final_texto {
    font-size: 2rem;
    width: 80%;
  }

  .home_h2 {
    font-size: 1.2rem;
  }

  .home-app-link img {
    width: 110px;
  }
}



/* -----------Inicio--final------------------------------------------------------------------*/


  
  
@media (max-width: 768px) {
  /* Ajustes para el título principal */
  .home_h1 {
      font-size: 2rem; /* Reduce el tamaño de la fuente */
      padding-left: 20px;
    padding-right: 20px;
      justify-content: center;
      text-align: center;
      align-items: center;
  }

  .home_h2{
    text-align: center;
    font-size: 1.5rem;
    color: #ffffff;
    font-family: 'Manrope';
    margin-top: 2rem;
    margin-bottom: 1rem;
    }

    .home-app-link img {
      max-width: 120px; /* Ajusta el tamaño de los logos de las apps */
      margin: .5rem;
  }

  /* Ajustes para los párrafos */
  .home_p_principal {
    padding-left: 20px;
    padding-right: 20px;
      font-size: 1.2rem; /* Reduce el tamaño de la fuente */
  }

  /* Ajustes para botones */
  .home_btn {
      width: 50%; /* Aumenta el ancho para una mejor accesibilidad */
      font-size: 14px; /* Reduce el tamaño de la fuente */
  }

  .home_centered-content {
    text-align: center;
    padding-top: 30%;
}
  
.home_block{
  height: 90vh;
}


.home_flex-container{
  width: 100%;
  height: 78%;
}


  
  .home_active {
    display: block; /* Muestra solo el elemento activo */
  }
  

  .home_texto_wrapper{
    background-color: #000;
  }
  .gif-section_texto{
    font-size: 1.5rem;
  }

  .gif-section_texto_descripcion{
    font-size: 1rem;
    width: 90%;
  }

  .btn{
    margin-top: 35px;
  }

}

@media (max-width: 768px) {

  .home_logo{
    width: 15%;
    height: 15%;
    justify-content: center;
    align-items: center;
  }

  .friends-section {
    height: 750px; /* Ajusta la altura según sea necesario */
    padding-bottom: 20px;
  }  

  .friends-section_texto {
    color: #fff;
    font-size: 2rem;
    font-family: 'Manrope';
    font-weight: 800;
    text-align: center;
  }
  
  .friends-section-img {
    display: flex;
    align-items: center; /* Centrado vertical */
    justify-content: center; /* Centrado horizontal */
    padding-top: 20px;
    flex-wrap: wrap; /* Permite que las imágenes se ajusten en varias líneas si es necesario */
  }
  
  .friends-section-img img {
    max-width: 35%; /* Hace que la imagen sea responsive */
    height: auto; /* Mantiene la proporción de la imagen */
    max-height: 100px; /* Ajusta esto para cambiar el tamaño de las imágenes */
    margin: 30px 20px; /* Añade un poco de espacio entre las imágenes */
  }

  .highlight {
    color: #7F6ADC; 
    font-size: 50px;
  }

}

@media (max-width: 480px) {
.home_h1{
  text-align: center;
  font-size: 2rem;
  color: #ffffff;
  font-family: 'Manrope';
  font-weight: 800;
  margin-top: 2rem;
  }
}
