@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


body {
  background-color: #000;
  color: #fff;
  font-family: "Manrope";
  margin: 0;
  padding: 0;
}

.IA-INU {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
}

.IA-INU_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 50px;
  text-align: center; /* Centrar el texto */
  width: 100%;
}

.IA-INU_h1 {
  color: #fff;
  font-size: 3.5rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  max-width: 700px;
  margin: 0 auto 20px auto;
}

.IA-INU_p {
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Manrope';
  max-width: 800px;
  margin: 0 auto 30px auto;
}

.IA-INU-avatar {
  display: block;
  margin: 20px auto;
}

.IA-INU-button {
  background-color: #7F6ADC;
  padding: 15px 30px;
  border-radius:30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
  display: inline-flex;
  align-items: center;
  margin-top: 30px;
  border: none;
  font-family: 'Manrope';
  font-weight: 800;
}

.IA-INU-button:hover {
  background-color: #C3F53E;
  color: #7F6ADC;
}

.IA-INU-button svg {
  margin-left: 8px;
}

.IA-INU_h2 {
  color: #fff;
  font-size: 3.5rem;
  font-family: 'Manrope', sans-serif;
  margin: 50px 0 20px 0;
  font-weight: 800;
}

.IA-INU-Pilares {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin-top: 5%;
}

.IA-INU-Pilares-ul {
  padding: 30;
  margin-top: 50px;
}

/* Estilos para la lista centrada */
.centered-list {
  list-style-type: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.centered-list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.centered-list li .IA-INU-circle {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.centered-list li .IA-INU-circle img {
  max-width: 90%;
  max-height: 90%;
}

.centered-list li .IA-INU-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.centered-list li .IA-INU-content h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: left;
  font-family: 'Manrope';
}

.centered-list li .IA-INU-content p {
  font-size: 1.2rem;
  color: #ccc;
  text-align: left;
  max-width: 400px; /* Añade un ancho máximo para controlar el corte del texto */
  margin-top: 5px;
  font-family: "Manrope";
}

/* Media Queries para dispositivos móviles */
@media screen and (max-width: 768px) {
  .IA-INU_h1 {
    font-size: 2rem;
  }

  .IA-INU_p {
    font-size: 1.2rem;
  }

  .IA-INU_h2 {
    font-size: 2.5rem;
  }

  .IA-INU-avatar {
    display: block;
    max-width: 30%;
  }

  .centered-list li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .centered-list li .IA-INU-circle {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .centered-list li .IA-INU-content {
    align-items: center;
    margin-left: 0;
  }

  .centered-list li .IA-INU-content h3 {
    font-size: 1.2rem;
  }

  .centered-list li .IA-INU-content p {
    font-size: 1rem;
    max-width: 90%; /* Ajuste el ancho máximo para móviles */
    text-align: center;
  }

  .IA-INU-button {
    padding: 10px 20px;
    font-family: 'Manrope';
  }

  .floating-word:nth-child(1),
  .floating-word:nth-child(2),
  .floating-word:nth-child(4),
  .floating-word:nth-child(6),
  .floating-word:nth-child(8),
  .floating-word:nth-child(9), 
  .floating-word:nth-child(3),
  .floating-word:nth-child(5),
  .floating-word:nth-child(7),
  .floating-word:nth-child(9) {
    display: none; /* Oculta algunas palabras en móviles */
  }
}

/* Estilo de las palabras flotantes */
.floating-word {
  position: absolute;
  font-family: 'Manrope';
  color: #6c6969;
  transition: 0.1s;
  white-space: nowrap; /* Evita que las palabras se rompan */

}

/* ---------------------------------------------------------Media Queries para dispositivos móviles */

/* Estilo para las palabras flotantes */
#floating-words {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Estilo de las palabras flotantes */
.floating-word {
  position: absolute;
  font-family: 'Manrope';
  color: #7F6ADC;
  transition: 0.1s;
}

/* Animaciones para cada palabra flotando, con posiciones aleatorias */
.floating-word:nth-child(1) {
  font-size: 20px;
  top: 20%;
  left: 20%;
  animation: float 25.2s infinite;
}
.floating-word:nth-child(2) {
  font-size: 25px;
  top: 40%;
  left: 10%;
  animation: float-reversed 20s infinite;
}
.floating-word:nth-child(3) {
  font-size: 23px;
  top: 30%;
  left: 60%;
  animation: float-second 22s infinite;
}
.floating-word:nth-child(4) {
  font-size: 20px;
  top: 90%;
  left: 30%;
  animation: float-second-reversed 24s infinite;
}
.floating-word:nth-child(5) {
  font-size: 21px;
  top: 80%;
  right: 10%;
  animation: float 21s infinite;
}
.floating-word:nth-child(6) {
  font-size: 22px;
  top: 20%;
  left: 70%;
  animation: float-reversed 23s infinite;
}

.floating-word:nth-child(7) {
  font-size: 26px;
  top: 25%;
  left: 5%;
  animation: float-reversed 23s infinite;
}

.floating-word:nth-child(8) {
  font-size: 19px;
  top: 60%;
  right: 5%;
  animation: float-reversed 23s infinite;
}

.floating-word:nth-child(9) {
  font-size: 18px;
  top: 60%;
  right: 80%;
  animation: float-reversed 23s infinite;
}

/* Animaciones */
@keyframes float {
  0% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-30px) translateX(10px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
}

@keyframes float-reversed {
  0% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(30px) translateX(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
}

@keyframes float-second {
  0% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(50px) translateX(-20px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
}

@keyframes float-second-reversed {
  0% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-50px) translateX(20px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
  }
}
