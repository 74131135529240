.payment_p_principal {
    font-family: 'Manrope-Regular';
    color: #ffffff;
    text-align: center;
    font-size: 1.3rem;
    padding-top: 25px;
    width: 80%;
    margin: 0 auto;
}

.deeplink {
    font-family: 'Manrope-Regular';
    color: #7F6ADC;
    text-align: center;
    font-size: 1rem;
    padding-top: 25px;
    margin: 0 auto;
}

.deplinkmanualp {
    font-family: 'Manrope-Regular';
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
    font-size: 1rem;
}

