.footer {
    background-color: #000;
    color: #fff;
    font-family: 'Manrope', sans-serif; /* Asegúrate de que la fuente 'Manrope' esté importada correctamente */
    padding: 40px 0;
    padding-top: 130px;
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px; /* O el ancho máximo que prefieras */
    margin: 0 auto;
    padding: 0 20px; /* Padding para dispositivos más pequeños */
}

.footer-section {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.footer-logo img {
    max-width: 100px; /* Ajusta el tamaño de tu logo */
    margin-bottom: 20px;
}

.footer-column h4 {
    margin-bottom: 20px;
    font-size: 20px; /* Tamaño de los títulos de las columnas */
    font-family: 'Manrope';
    font-weight: 800;
    text-align: left ;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    text-align: left ;
}

.footer-column ul li a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
    font-family: 'Manrope';
    font-size: 16px; /* Tamaño de los enlaces */
    font-weight: normal;
}

.footer-apps {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-app-link img {
    max-width: 150px; /* Ajusta el tamaño de los logos de las apps */
    min-width: 150px;
    margin-bottom: 10px;
}

.footer-social-media {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.footer-social-media a {
    color: #fff;
    font-size: 24px; /* Tamaño de los iconos de redes sociales */
    margin: 0 10px;
}

.footer-bottom-text p {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 20px; /* Tamaño del texto de abajo */
}

.footer-bottom-Linadi{
    text-align: center;
    margin-top: 10px;
    color: "#c4c4c4";
    font-family: 'Manrope';

}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        flex-direction: column;
        align-items: center;
    }

    .footer-apps {
        align-items: center;
        padding-top: 20px;
    }

    /* Ajustes adicionales */
    .footer-logo img {
        max-width: 80px; /* Reduce el tamaño del logo */
    }

    .footer-column ul li a {
        font-size: 14px; /* Reduce el tamaño de la fuente de los enlaces */
    }

    .footer-app-link img {
        max-width: 120px; /* Reduce el tamaño de los logos de las apps */
    }

    .footer-social-media a {
        font-size: 20px; /* Reduce el tamaño de los iconos de redes sociales */
    }

    .footer-bottom-text p {
        font-size: 14px; /* Reduce el tamaño del texto de abajo */
    }

    .footer-column h4 {
        padding-top: 20px;
        text-align: center;
    }

    .footer-column ul {
        text-align: center;
    }
}