
.cursos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 0;
  }
  
  .cursos-text {
    color: #fff;
    font-size: 3.5rem;
    font-family: 'Manrope', sans-serif;
    max-width: 800px;
    margin: 0 auto 20px auto;
  }
  
  .cursos-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    padding: 10px;
    text-align: center; /* Centrar el texto */
    width: 100%;
  }

  @media (max-width: 480px) {
    .cursos-text {
        font-size: 2rem;
    }
  
    .cursos-content {
        padding: 20px;
    }
  }


/* ---bolitas--------------------------------------------------------------------- */
#parallax-lvl-0, #parallax-lvl-1, #parallax-lvl-2, #parallax-lvl-3 {
    position: absolute;
    top: 0;
  }
  #parallax-lvl-1 {
    z-index:0;
  }
  #parallax-lvl-2 {
    z-index:10;
  }
  #parallax-lvl-3 {
    z-index:100;
  }
  
  .bubble {
    border-radius: 100%;
    position: absolute;
    opacity: 0.7;
  }
  .size1 {
    width:100px;
    height:100px;
  }
  .size2 {
    width:200px;
    height:200px;
  }
  .size3 {
    width:300px;
    height:300px;
  }
  .size4 {
    width:400px;
    height:400px;
  }
  .blanca {
    background-color: #C3F53E;
  }
  .rosa {
    background-color: #7F6ADC;
  }
  
  
  /* Bubble positions */
  #b0-1 {
    top:550px;
    right:700px;
  }
  
  #b0-2 {
    top:700px;
    left:-800px;
  }
  
  #b0-3 {
    top:700px;
    left:550px;
  }
  
  
  #b1-1 {
    top:1650px;
    left:-200px;
  }
  #b1-2 {
    top:1000px;
    left:-220px;
  }
  #b1-3 {
    top:1400px;
    left:560px;
  }
  #b2-1 {
    top:1800px;
    left:-550px;
  }
  #b2-2 {
    top:2200px;
    left:200px;
  }
  
  #b3-1 {
    top:2710px;
    left:500px;
  }
  #b3-2 {
    top:2450px;
    left:-865px;
  }
  

/* -------------Recompensas------------------------------------------------------------------*/

  /* -------------Recompensas------------------------------------------------------------------*/
  .home_texto_wrapper{
    font-family: 'Manrope-Bold', sans-serif;
    display: flex;
    margin: 0;
    padding: 0;

  }
  
  
  .home_texto_izquierda{
    display: flex;
    align-items: center;
    color: #fff;
    background: #000;
    display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinea el contenido a la izquierda */
  text-align: right; /* Asegúrate de que el texto dentro también esté alineado a la izquierda */
  width: 50%;
  height: 100vh;
  position: sticky;
  top: 0;
  font-size: 40px;
  }
  
  .home_texto_derecha{
    width:50%;
    color: white;
    font-size: 40px;
  }
  
  .home_texto_derecha div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .home_texto_derecha_h2{
    width: 50%;
    text-align: left;
  }
  
  
  .home_texto_derecha div:nth-child(1){
    background: #000;
    color: #fff;
    
  }
  
  .home_texto_derecha div:nth-child(2){
    background: #000;
    color: #fff;
  }
  
  .home_texto_derecha div:nth-child(3){
    background: #000;
    color: #fff;
  }
  
  
  

@media (max-width: 768px) {


  .home_texto_derecha div:nth-child(1){
    width: 100%;

  }

  .home_texto_derecha{
    width: 100%;
  }

  .home_texto_izquierda{
    background-color: transparent;
    width: 80%;
  }

  .home_texto_derecha_h2_1{
    font-size: 2.5rem;
    justify-content: center;
  }

  .home_texto_derecha_h2{
    font-size: 1.8rem;
    justify-content: center;
  }

}
      
/* -----------fin--Recompensas------------------------------------------------------------------*/
      
      .cursos-avatar {
        display: block;
        margin: 20px auto;
        width: 35%;
      }

      .slider {
        width: 150%;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        padding-bottom: 60px;
      }

      .cursos-recompensas {
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 50px 0;
      }

      .cursos_final_texto{
        color: #fff;
        font-size: 3.5rem;
        font-family: 'Manrope';
        font-weight: 800;
        width: 70%;
        margin: 0 auto 10px;
      }


      /* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 768px) {

  .cursos-content {
      padding: 20px;
      padding-bottom: 25px;
  }

  .cursos-avatar {
      width: 100%;
  }


  .cursos_final_texto {
      font-size: 2rem;
      width: 90%;
  }

  .slider {
      width: 100%;
      height: auto;
      padding: 20px;
  }
  
  .bubble {
      opacity: 0.5;
  }

  /* Ajusta las burbujas para pantallas pequeñas */
  .size1 {
      width: 50px;
      height: 50px;
  }
  .size2 {
      width: 100px;
      height: 100px;
  }
  .size3 {
      width: 150px;
      height: 150px;
  }
  .size4 {
      width: 200px;
      height: 200px;
  }

  .slider {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-bottom: 150px;
  }

  .final-section{
    padding: 0px 0;
  }


}

@media (max-width: 480px) {
  .bubble {
      opacity: 0.5;
  }

  /* Smaller sizes for very small screens */
  .size1 {
      width: 30px;
      height: 30px;
  }
  .size2 {
      width: 60px;
      height: 60px;
  }
  .size3 {
      width: 100px;
      height: 100px;
  }
  .size4 {
      width: 120px;
      height: 120px;
  }

  /* Adjust bubble positions further for small devices */
  #b0-1 {
      top: 700px;
      right: 30px;
  }
  #b0-2 {
    top: 678px;
    left: -188px;
  }
  #b0-3 {
      top: 350px;
      left: 100px;
  }
  #b1-1 {
    top: 1600px;
    left: 10px;
  }
  #b1-3 {
      top: 700px;
      left: 80px;
  }
  #b2-1 {
      top: 900px;
      left: 20px;
  }
  #b2-2 {
      top: 1100px;
      left: -30px;
  }
  #b3-1 {
      top: 1300px;
      left: -100px;
  }
  #b3-2 {
      top: 1500px;
      left: 70px;
  }
}