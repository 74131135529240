@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* HEADER */
.header {
  background-color: #000;
  padding: 2vh 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

/* CONTENEDOR DEL HEADER */
.header-container {
  max-width: 90vw; /* Se adapta al tamaño del viewport */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* LOGO */
.logo {
  flex: 0 0 auto;
  margin-right: 2vw; /* Espaciado flexible */
}

.logo img {
  max-width: 5vw; /* Se adapta al tamaño de la pantalla */
  min-width: 60px; /* Evita que sea demasiado pequeño */
}

/* NAVEGACIÓN */
.nav {
  display: flex;
  flex: 1;
  justify-content: center;
}

.nav-link {
  font-size: 1.7rem; /* Relativo al tamaño base del texto */
  margin: 0 2vw;
  text-decoration: none;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  color: #fff;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.nav-link:hover {
  color: #C3F53E;
}

/* BOTÓN DESCARGAR */
.button {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #fff;
  background-color: #7F6ADC;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  border-radius: 30px;
  font-size: 1rem;

  max-width: 20vw;
  min-width: 10vw;
  max-height: 5vw;
  min-height: 2vw;

}

.button:hover {
  background-color: #C3F53E;
  color: #7F6ADC;
}

.button-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: #7F6ADC
}

/* RESPONSIVE DESIGN */

/* Tablets y pantallas medianas */
@media screen and (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .nav {
    flex-direction: row;
    justify-content: center;
  }

  .nav-link {
    font-size: 1.2rem;
    margin: 0 1vw;
  }

  .logo img {
    display: none;
  }

  .button {
    display: none;
  }
}

/* Teléfonos móviles */
@media screen and (max-width: 480px) {
  .nav-link {
    font-size: 1rem;
    margin: 2vh 2vw;
  }

  .logo img {
    display: none;
  }

  .button {
    display: none;
  }
}
