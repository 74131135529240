

body {
  background-color: #000;
  color: #fff;
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
}

/* Estilos generales */
.Expertos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
}

.Expertos_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 50px;
  text-align: center;
  width: 100%;
}

.Expertos_h1 {
  color: #fff;
  font-size: 3.5rem;
  font-family: 'Manrope', sans-serif;
  width: 60%;
}

.Expertos_p {
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Manrope', sans-serif;
  max-width: 800px;
  margin-top: 2%;
}

.Expertos-avatar {
  display: block;
  margin: 20px auto;
}

.Expertos_p2 {
  color: #fff;
  font-size: 2.3rem;
  font-family: 'Manrope', sans-serif;
  max-width: 800px;
  margin: 0 auto 30px auto;
}

/* Flujo de expertos */
.Expertos-flujo {
  padding: 50px 20px; /* Reducir el padding en móviles */
}

.timeline-item {
  display: flex;
  flex-direction: row; /* Cambiar a fila en lugar de columna */
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 20px; /* Espacio entre la imagen y el texto */
  padding: 25px;
}

.timeline {
  align-items: center; /* Centrar los elementos en móviles */
}


.expertos-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 75%;
  padding-left: 650px;
  padding-top: 10px;
}

.expertos-text-izquierdo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 80%;
  padding-right: 700px;
  padding-top: 10px;
}

.Expertos_h3-izquierdo, .Expertos_h3, .Expertos_h3-center {
  color: #fff;
  font-size: 2.2rem;
  font-family: 'Manrope', sans-serif;
  margin: 0;
}

.expertos-circle, .expertos-circle1, .expertos-circle2, .expertos-circle3 {
  width: 160px;
  height: 170px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExdHowa255djg4cG9wMHI1MGwyN29hNmVxenJ0Y2VzaGpidnVoZ2k4MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/8lLf4QfofKzcpxlwFp/giphy.gif');
  z-index: 0;
}

.expertos-circle2 {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExc2tja3JtOXBvaWxuNGppdzBjNmk5dWFscW9laHdxZ2d6dWU0eW1pOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/1iOOq0JMpCME9aYwfF/giphy.gif');
}

.expertos-circle1 {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExenl3emFncXlscGVoZzliNXQ5b2ExZTdiNWIzanpodWVkMTBkcGIzMCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/9QuH75kDS0NqckvoSA/giphy.gif');
}

.expertos-circle3 {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMW55NDI3bDFocHk1dG9mbHdvcjQ0ZGN6OGQ3ZmY4dXF6dTc5Z3I1MCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/jNc1pOwng7PBTuwUMV/giphy.gif');
  margin-bottom: 80px;
}

.expertos-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  padding-top: 220px;
}

/* Sección empresa */
.empresa-section {
  padding: 50px;
  display: flex;
  justify-content: center;
}

.empresa-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
}

.empresa-item {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  min-width: 450px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Expertos_content_pasos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 30px 0; /* Añadir un margen para separar del contenido superior e inferior */
}

.Expertos_content_pasos .Expertos_h1 {
  font-size: 2.5rem; /* Tamaño adecuado para desktop */
  margin: 0 auto;
}


.empresa-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #fff;
  font-family: "Manrope";
}

.empresa-description {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  font-family: "Manrope";
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empresa-circle--legal {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/8hn8SROAxffok1OJQ7/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.empresa-circle--contable {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/VEsEbxI7kOpkItLtJY/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.empresa-circle--emprendimiento {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/UPYC65az80IEJcPXbC/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.empresa-circle--nutricional {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/TITLn6h5CtL9G9mWh3/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.empresa-circle--laboral {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/aNqWZH7oSndArTWGYn/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

.empresa-circle--dental {
  width: 90px;
  height: 90px;
  background-image: url('https://media.giphy.com/media/POBF9CfrtFt4w8CH6c/giphy.gif');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
}

/* Sección GIF */
.Expertos_gif-section {
  background-color: #000;
  height: 750px;
  width: 100%;
  padding-top: 5%;
}

.Expertos_gif-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0%;
  bottom: 0;
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWh0dnM0Y2UydWJ3ZTF4Z3dtOWMxN2U5OTZqNnZobnhucHQ3cXo5NyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/5vcg3mHyNU1BS/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  z-index: 0;
  width: 100%;
}

.Expertos_content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
}

.Expertos_button {
  background-color: #7F6ADC;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s;
}

.Expertos_button:hover {
  background-color: #fff;
  color: black;
}

/* Ajustes para pantallas móviles */
@media (max-width: 768px) {
  .Expertos_h1 {
    font-size: 2.5rem;
    width: 80%;
  }

  .Expertos_p {
    font-size: 1.2rem;
    width: 90%;
    padding-top: 20px;
  }

  .Expertos-avatar {
    width: 30%;
    height: auto;
  }

  .Expertos-flujo {
    padding: 100px 20px;
  }

  .expertos-text {
    width: 100%;
    padding-left: 20px;
  }

  .expertos-text-izquierdo {
    width: 100%;
    padding-right: 20px;
    text-align: left;
  }

  .Expertos_content {
    padding: 20px;
  }

  .empresa-grid {
    grid-template-columns: 1fr;
  }

  .Expertos_gif-section {
    height: 600px;
    padding-top: 30%;
  }

  .Expertos_gif-section::before {
    left: 0;
    right: 0;
  }

  .Expertos_content_pasos {
    padding-top: 20%;
    align-self: center;
    text-align: center;
  }

  .Expertos_content-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .timeline-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 50px;
    padding: 5px;
  }

  .expertos-text-center {
    padding-top: 0;
  }

  .expertos-circle, .expertos-circle1, .expertos-circle2, .expertos-circle3 {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }

  .expertos-text-izquierdo, .expertos-text, .expertos-text-center {
    width: auto;
    padding-left: 100px;
    padding-right: 20px;
  }


  .Expertos_h3-izquierdo {
    text-align: left !important; /* Forzar la alineación a la izquierda */
    margin-left: 0; 
    width: 100%; 
  }
  

  .Expertos_h3, .Expertos_h3-izquierdo, .Expertos_h3-center {
    font-size: 1.8rem;
    text-align: left;
    padding-left: 15px;
  }

  .Expertos_p {
    font-size: 1.2rem;
    text-align: left;
    padding-top: 20px;
  }
}


@media (max-width: 480px) {
  .Expertos_h1 {
    font-size: 2rem;
    width: 90%;
  }

  .Expertos_p {
    font-size: 1rem;
    text-align: center;
  }

  .empresa-item {
    max-width: 100%;
    min-width: 100%;
  }

  .empresa-circle--legal, .empresa-circle--contable, .empresa-circle--emprendimiento,
  .empresa-circle--nutricional, .empresa-circle--laboral, .empresa-circle--dental {
    width: 70px;
    height: 70px;
  }
}
