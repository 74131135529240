@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.home_carousel {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1250px;
  max-height: 550px;   
  overflow: hidden;
  position: relative;
  margin: auto;
}

.home_carousel_nav {
  position: absolute;
  right: 0;
  z-index: 2;
  background-color: #7F6ADC;
  bottom: 0;
  right: 500px;
  border-radius: 20px;
  padding: 5px 20px;
}

.home_carousel_icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  fill: #C3F53E;
}

.home_carousel_arrow {
  cursor: pointer;
  display: inline-block;
  padding: 20px 20px;
  position: relative;
}

.home_carousel-item {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.home_carousel-item__image {
  width: 50%;
  height: 60%;
  order: 2;
  align-self: flex-end;
  flex-basis: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  transform: translateX(100%);
  transition: 0.6s all ease-in-out;
  margin: auto;
}

.home_carousel-item--1 .home_carousel-item__image {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMHF0eDR6Nndla3VoZnM0YjZ6dDM1Yjdic2NyOWNyajltcmI0N2lkNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26FfbM5bbhCdLANW0/giphy.gif');
}

.home_carousel-item--2 .home_carousel-item__image {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExbGp6dnF5c2VvNzgxMXQ0bnZlMWU2ZjBhZWsyeHQ2Z2s2OW5pZHpweCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l2JedTTnDTPXSmPYs/giphy.gif');
}

.home_carousel-item--3 .home_carousel-item__image {
  background-image: url('https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcWwyOXE5cm84bmpndWxkdnp0ZDR3dnBmN3h0YjRmOHhkZGtsb21yaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/PNre1wHffFEcKCPoUn/giphy.gif');
}

.home_carousel-item__info {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  order: 1;
  left: 0;
  margin: auto;
  width: 50%;
}

.home_carousel-item__title {
  margin: 15px 0 0 0;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 70px;
  line-height: 60px;
  color: #fff;
  transform: translateY(25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s all ease-in-out;
  text-align: right;
}

.home_carousel-item__description {
  transform: translateY(25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s all ease-in-out;
  margin-top: 35px;
  font-family: 'Manrope';
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 35px;
  text-align: right;
  font-size: 20px;
}

.home_carousel-item__btn {
  width: 35%;
  color: #C3F53E;
  font-family: "Manrope";
  letter-spacing: 3px;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0;
  width: 35%;
  font-weight: 700;
  text-decoration: none;
  transform: translateY(25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s all ease-in-out;
}

.home_active {
  opacity: 1;
  visibility: visible;
  position: relative;
  z-index: 1;
}

.home_active .home_carousel-item__title,
.home_active .home_carousel-item__description,
.home_active .home_carousel-item__btn {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.home_active .home_carousel-item__image {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .home_carousel {
      max-width: 100%;
      max-height: 100%;
  }

  .home_carousel-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      z-index: 0;
  }

  .home_carousel_nav {
    display: flex;
    justify-content: space-between;
    order: 3;
    right: 60px;
    bottom: 0;
    width: 70%;
    padding: 0 10px;
  }

  .home_carousel_arrow {
      margin: 0 1rem;
      padding: 10px;
  }

  .home_carousel_icon {
      width: 24px;
      height: 24px;
  }

  .home_carousel-item__image {
      width: 70%;
      height: auto;
      max-height: 200px;
      background-size: cover;
      order: 2;
      margin-bottom: 90px;
  }

  .home_carousel-item__info {
      padding: 20px;
      text-align: center;
      width: 100%;
      order: 1;
  }

  .home_carousel-item__title {
    font-size: 2.5rem;
    text-align: center;
  }

  .home_carousel-item__description {
      font-size: 1.2rem;
      padding: 0 1rem;
      text-align: center;
  }

  .home_carousel-item__btn {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: auto;
  }
}
