

body {
    background-color: #000;
    color: #fff;
    font-family: "Manrope", sans-serif;
    margin: 0;
    padding: 0;
}

.Friends {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Asegura que el contenedor cubra toda la altura de la pantalla */
  }

/* Secciones generales */
.header-section,
.content-section,
.two-up {
    min-height: 70vh;
    display: grid;
    place-items: center;
    gap: min(2vw, 2rem);
    grid-template-columns: repeat(4, 1fr);
    padding: 2rem;
}

/* Animaciones flotantes */
.floating {
    position: relative;
    transition: transform 0.1s ease-out; /* Mejora de transición */
}

/* Ajustes para hacer el parallax más fluido */
.floating {
    --float-range: 50vh;
    --float-factor: 1;
    transform: translateY(0); /* Iniciar sin desplazamiento */
    transition: transform 0.1s ease-out; /* Transición suave */
    will-change: transform;
}

.floating.reverse {
    --float-range: 60vh;
    
}

/* Factores de flotación (ajustados para una experiencia más fluida) */
.ff0 { --float-factor: 0; }
.ff1 { --float-factor: 0.1; }
.ff2 { --float-factor: 0.2; }
.ff3 { --float-factor: 0.1; }
.ff4 { --float-factor: 0.4; }
.ff5 { --float-factor: 0.2; }
.ff6 { --float-factor: 0.6; }
.ff7 { --float-factor: 0.4; }
.ff8 { --float-factor: 0.8; }
.ff9 { --float-factor: 0.4; }
.ff10 { --float-factor: 0.4; }

.textprincipal {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    font-family: 'Manrope';
    text-align: left;
    left: 90%;
  width: 150%;

}

.principal_p {
font-size: 2rem;
  margin-bottom: 1rem;
  margin-left: 20rem;
  font-family: 'Manrope';
  text-align: left;
  color: #fff;
  width: 130%;
}

.special-text-friends {
    font-size: 2rem;
    font-weight: bold;
    color: #7F6ADC;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Manrope';
    font-weight: 800;
  }

/* Estilos para imágenes */
/* Contenedor que envuelve la imagen de fondo y el logo */
.image-container {
    position: relative;
    display: inline-block; /* O `block` si prefieres que cada imagen ocupe el ancho completo */
  }
  
  /* Imagen de fondo */
  .background-image {
    display: block;
    width: 80%;
    height: auto;
    opacity: 0.5; /* Ajusta la opacidad según prefieras */
    border-radius: 10%;
  }
  
  /* Imagen del logo */
  .logo-image {
    position: absolute;
    top: 50%; /* Ajusta según donde quieras que aparezca el logo */
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el logo */
    width: 40%; /* Ajusta el tamaño del logo según sea necesario */
    height: auto;
  }

  

  .floating_p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    font-family: 'Manrope';
    text-align: left;
    color: #fff;
  }

  .background-image-XL {
    width: 100%;
    height: auto;
    border-radius: 50%;
    opacity: 0.5;
  }

  .XL {
    width: 90%;
    height: auto;
  }
  
  .XLLogo {
    width: 80%;
    height: auto;
  }

  .floating-mf{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-family: 'Pally-Bold';
    text-align: left;
    color: #fff;
    width: 150%;
  }

  .floating-mf-p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-family: 'Pally-Regular';
    text-align: left;
    color: #fff;
    width: 150%;
  }

/* Sección con dos columnas */
.two-up {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 4rem 2rem;margin-bottom: 500px;
    margin-top: 30%;
}

.two-up article {
    max-width: 400px;
}

.final-section-friends {
    margin-top: 55%; 
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-decoration: none; /* O el estilo que desees */
  padding: 0;
}



/* Ajustes para hacer el contenido más fluido en pantallas más pequeñas */
@media screen and (max-width: 768px) {
    .Friends {
        padding-bottom: 10px; 
    }

    .content-section {
        display: flex;
        flex-direction: column; /* Alinear los elementos en columna */
        align-items: center; /* Centrar horizontalmente */
        justify-content: center; /* Centrar verticalmente */
        text-align: center; /* Centramos el texto */
        padding: 1rem;
    }

    .image-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }   

    .background-image-XL {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }


    .XLLogo {
        width: 80%;
        height: auto;
      }

    .header-section {
        display: flex;
        flex-direction: column; /* Para que los elementos se posicionen uno debajo del otro */
        align-items: center; /* Centramos los elementos */
        justify-content: center;
        text-align: center; /* Aseguramos que el texto esté centrado */
    }

    .floating {
        transform: none !important;
        transition: none !important;
    }

    .textprincipal {
        font-size: 2rem; 
        margin: 0; 
        width: 90%; 
        left: 0;
        padding-bottom: 25px;
        text-align: center;
    }

    .principal_p {
        font-size: 1.2rem;
        margin: 0; 
        width: 90%; 
        text-align: center;
    }

    .header-section,
    .content-section {
        grid-template-columns: 1fr; 
    }


    .two-up {
        grid-template-columns: 1fr;
        margin-bottom: 0px;
    }

    .header-section,
    .content-section {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 2rem;
    }

    p {
        font-size: 1rem;
    }

    .background-image{
        width: 80%;
        height: auto;
    }

    .background-image-XL
    {
        opacity: 0.5;
    }

    .floating_p {
        font-size: 1.3rem;
        text-align: center;
        margin-left: 0px;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .floating-mf {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .floating-mf-p {
        font-size: 1.3rem;
        width: 100%;
        text-align: center;
    }

    .XL {
        width: 100%;
        height: auto;
        min-width: 350px;
    }

    .ff0 { --float-factor: 0; }
    .ff1 { --float-factor: 0.05; }
    .ff2 { --float-factor: 0.1; }
    .ff3 { --float-factor: 0.05; }
    .ff4 { --float-factor: 0.2; }
    .ff5 { --float-factor: 0.1; }
    .ff6 { --float-factor: 0.3; }
    .ff7 { --float-factor: 0.2; }
    .ff8 { --float-factor: 0.4; }
    .ff9 { --float-factor: 0.2; }
    .ff10 { --float-factor: 0.2; }

    .final-section-friends{
      margin-top: 25%
    }

}
